import { createResource, Entity, RestEndpoint, RestGenerics, schema as RestSchema } from '@data-client/rest'
import { AxiosError } from 'axios'
import { ApiError } from '../api/error'
import { removeEmptyParams } from '../helpers/objects'
import AxiosService from '../services/AxiosService'

export type ApiPaginationQuery = {
  page_number?: number
  page_size?: number
}

export class ApiPaginationHeader {
  page_number?: number = 0
  page_size?: number = 0
  total_pages?: number = 0
  total_count?: number = 0
  has_previous_page?: boolean = false
  has_next_page?: boolean = false
  // pk(): string {
  //   return `${this.page_number}`
  // }
}

export abstract class ApiEntity extends Entity {
  readonly id: number | string = 0
  pk() {
    return `${this.id}`
  }
}

export class ApiEndpoint<O extends RestGenerics = any> extends RestEndpoint<O> {
  urlPrefix = `${import.meta.env.REACT_APP_API_URL}`

  url<A extends Record<string, unknown>>(...args: A[]) {
    const cleanedParams = args ? args.map((value) => removeEmptyParams(value)) : ([] as A[])
    return super.url(...cleanedParams as never)
  }

  async fetchResponse(input: RequestInfo, init: RequestInit) {
    const url = typeof input === 'string' ? input.replace('/the_only_one', '') : input.url

    const { privateInstance } = AxiosService()
    return await privateInstance
      .request({
        url: url,
        method: init.method,
        data: init.body,
      })
      .then((response) => {
        if (!response.status.toString().startsWith('2')) {
          throw new ApiError(response.status, JSON.parse(response.data))
        }
        return response
      })
      .catch((err: AxiosError) => {
        const response = err?.response
        throw new ApiError(response?.status, response?.data)
      })
  }

  parseResponse(response: AxiosResponse<any, any>) {
    return response.data ?? []
  }
}

interface ApiResourcePaths {
  list?: string
  get?: string
  partialUpdate?: string
}

export function CreateApiResource<U extends string, S extends Schema, P extends ApiResourcePaths>({
  path,
  schema,
  Endpoint = ApiEndpoint,
  paths = {},
  optimistic = false,
}: {
  readonly path: U
  readonly schema: S
  readonly Endpoint?: typeof ApiEndpoint
  readonly paths?: Partial<P>
  readonly optimistic?: boolean
}) {
  const base = createResource(
    {
      path, schema, Endpoint,
      optimistic: optimistic,
      urlPrefix: import.meta.env.REACT_APP_API_URL
    })

  const partialUpdate = new ApiEndpoint({
    path: paths.partialUpdate ?? base.partialUpdate.path,
    method: 'PATCH',
    body: {} as any,
    schema: schema,
    process(value): S { return value }
  })

  var getPaginated = base.getList.extend({
    path: paths.list ?? base.get.path.replace('/:id', ''),
    searchParams: {} as ApiPaginationQuery & Record<string, any>,
    paginationField: 'page_number',
    schema: {
      items: new RestSchema.Collection([schema], {
        nonFilterArgumentKeys: ['page_size', 'page_number']
      }),
      pagination: new ApiPaginationHeader()
    },
    parseResponse(response: AxiosResponse<any, any>) {
      const pagination_header = response?.headers?.get('pagination') ?? response?.headers?.get('Pagination')
      if (pagination_header) {
        return { items: response.data, pagination: JSON.parse(pagination_header) as ApiPaginationHeader }
      }
      return response.data ?? []
    }
  })

  const list = new ApiEndpoint({
    path: paths.list ?? base.get.path.replace('/:id', ''),
    method: 'GET',
    body: undefined,
    schema: [schema],
    process(value): S[] { return value }
  })

  return {
    ...base,
    list,
    getPaginated,
    partialUpdate,
  }
}
