import { createContext, useContext, useState } from 'react'
import { notification } from 'antd'
import { ApiError } from '../api/error'
import { ArgsProps } from 'antd/es/notification/interface'

interface INotification {
  notify: (props: ArgsProps) => void
  notifyOnError: (error: ApiError) => void
}

const Notification = createContext<INotification>({
  notify: (props) => undefined,
  notifyOnError: (error) => undefined,
})

const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification()

  const notify = (props: ArgsProps) => api.open(props)

  const notifyOnError = (error: ApiError) => {
    api.error({
      message: error.title,
      description: error.errorDescription,
    })
  }
  return (
    <Notification.Provider
      value={{
        notify,
        notifyOnError,
      }}
    >
      {contextHolder}
      {children}
    </Notification.Provider>
  )
}

const useNotification: () => INotification = () => useContext(Notification)

export { NotificationProvider, useNotification }
