import { Space, SpaceProps as AntSpaceProps } from 'antd'
import { FC, PropsWithChildren } from 'react'

type SpaceProps = AntSpaceProps & {
  justify?: '' | 'left' | 'center' | 'right' | 'space-between'
  align?: 'center' | 'baseline' | 'start' | 'end'
}

const HorizontalSpace: FC<SpaceProps & PropsWithChildren> = ({ justify, style, children, align, ...baseProps }) => {
  return (
    <Space
      wrap={false}
      style={{ width: '100%', justifyContent: justify, alignContent: align, ...style }}
      direction={'horizontal'}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

export default HorizontalSpace
