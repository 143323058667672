// import { Result } from 'antd'
// import React from 'react'
import { ApiError } from '../api/error'
import { NetworkError } from '@data-client/react'
import { Result } from 'antd'
import { Component, FC } from 'react'

// function isApiError(error: ApiError | unknown): error is ApiError {
//   return Object.prototype.hasOwnProperty.call(error, 'name')
// }

interface Props {
  children: React.ReactNode
  pathname: any
}

interface State<E extends ApiError> {
  error?: E
  pathname: any
}

class ApiErrorBoundary<E extends ApiError> extends Component<Props, State<E>> {
  state: State<E> = {
    pathname: '',
  }

  static getDerivedStateFromError(error: ApiError | any) {
    if (error instanceof ApiError) {
      return { error }
    } else {
      throw error
    }
  }

  componentDidCatch(error, errorInfo) {}

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ error: undefined })
    }
  }

  render(): JSX.Element {
    if (!this.state.error) {
      return this.props.children as any
    }
    switch (this.state.error.code) {
      case 403:
        return <Result status={'403'} title={`${this.state.error.code}`} subTitle={this.state.error.errorDescription} />
      case 404:
      case 406:
        return <Result status={'404'} title={`${this.state.error.code}`} subTitle={this.state.error.errorDescription} />
      default:
        return (
          <Result status={'error'} title={`${this.state.error.code}`} subTitle={this.state.error.errorDescription} />
        )
    }
  }
}

type ErrorComponentProps<T extends Error> = { error: T }

const ErrorComponent: FC<ErrorComponentProps<Error & NetworkError>> = ({ error }) => {
  console.log(error)
  if (error instanceof ApiError) {
    switch (error?.status) {
      case 403:
        return <Result status={'403'} title={`${error.code}`} subTitle={error.errorDescription} />
      case 404:
      case 406:
        return <Result status={'403'} title={`${error.code}`} subTitle={error.errorDescription} />
      default:
        return <Result status={'error'} title={`${error.code}`} subTitle={error.errorDescription} />
    }
  }
  return <Result status={'error'} title={error.name} subTitle={error.message} />
}

export { ErrorComponent, ApiErrorBoundary }
