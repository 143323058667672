import { FC,  useEffect, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { withPrefix } from 'src/sdk/providers/ConfigProvider'
import './Loader.scss'

const LoadingIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

const FullPageLoader: FC<{ loading: boolean; type?: 'light' | 'dark' }> = ({ loading, type = 'dark' }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (loading) {
      setVisible(true)
    } else {
      setTimeout(() => {
        setVisible(false)
      }, 1000)
    }
  }, [loading])

  return visible ? (
    <div
      className={withPrefix('full-page-loader', `full-page-loader-${type}`, loading ? 'full-page-loader-active' : '')}
    >
      <Spin indicator={LoadingIcon} />
    </div>
  ) : null
}

const SectionLoader: FC<{ loading?: boolean }> = ({ loading = true }) => {
  return loading ? (
    <div className={withPrefix('section-loader', loading ? 'section-loader-active' : '')}>
      <Spin className={withPrefix('section-loader-spinner')} indicator={LoadingIcon} />
    </div>
  ) : null
}

const Spinner: FC<{ loading?: boolean }> = ({ loading = true }) => {
  return loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null
}

export { FullPageLoader, SectionLoader, Spinner }
