enum Breakpoint {
  XS = 480,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

enum BreakpointMin {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

enum BreakpointMax {
  XS = BreakpointMin.SM - 1,
  SM = BreakpointMin.MD - 1,
  MD = BreakpointMin.LG - 1,
  LG = BreakpointMin.XL - 1,
  XL = BreakpointMin.XXL - 1,
}

function getBreakpoint(width: number): Breakpoint {
  if (Breakpoint.XS <= width) return Breakpoint.XS
  if (Breakpoint.SM <= width && Breakpoint.XS > width) return Breakpoint.SM
  if (Breakpoint.MD <= width && Breakpoint.SM > width) return Breakpoint.MD
  if (Breakpoint.LG <= width && Breakpoint.MD > width) return Breakpoint.LG
  if (Breakpoint.XL <= width && Breakpoint.LG > width) return Breakpoint.XL
  return Breakpoint.XXL
}

export { Breakpoint, BreakpointMin, BreakpointMax, getBreakpoint }

export type ScreenSize = { width: number; height: number }
