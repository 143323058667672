import { Spin } from 'antd'
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react'
import { useAuth } from 'src/sdk/providers/AuthProvider'

type SiderState = '' | 'hidden' | 'collapsed' | 'expanded'

type LayoutStateProps = {
  pageLoading: boolean
  setPageLoading: Dispatch<SetStateAction<boolean>>
  siderState: SiderState
  setSiderState: Dispatch<SetStateAction<SiderState>>
  footerVisible: Boolean
  setFooterVisible: Dispatch<SetStateAction<boolean>>
}

const LayoutState = createContext<LayoutStateProps>({
  pageLoading: false,
  setPageLoading: (value) => value,
  siderState: '',
  setSiderState: (value) => value,
  footerVisible: true,
  setFooterVisible: (value) => value,
})

const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false)
  const [siderState, setSiderState] = useState<SiderState>('')
  const [footerVisible, setFooterVisible] = useState(true)

  return (
    <LayoutState.Provider
      value={{
        pageLoading,
        setPageLoading,
        siderState,
        setSiderState,
        footerVisible,
        setFooterVisible,
      }}
    >
      <Spin spinning={pageLoading}>{children}</Spin>
    </LayoutState.Provider>
  )
}

function useLayoutState(): LayoutStateProps {
  return useContext(LayoutState)
}

export { LayoutProvider, useLayoutState }
