import { Entity } from "@data-client/rest";
import { ApiEntity } from "../ApiResource";
import { ComponentScope } from "./componentScope";

class UserRole extends ApiEntity {
  readonly id: number = 0
  readonly slug: string = ''
  readonly description: string = ''
  readonly scopes: UserRoleScope[] = []
}

class UserRoleScope extends Entity {
  readonly name: string = ''
  readonly slug: string = ''
  readonly description: string = ''
  readonly component: ComponentScope = ''
  readonly type: 'read' | 'write' | 'delete' = 'read'
  pk(): string {
    return this.slug
  }
}

export { UserRole };
