import { ApiEntity } from "./ApiResource";
import { Company } from "./company";

type GlobalNetworkCompany = Pick<Company,
    'name' |
    'icon_url' |
    'logo_url' |
    'address' |
    'address2' |
    'city' |
    'state' |
    'zip_code' |
    'country' |
    'version'
>

class GlobalNetwork extends ApiEntity {
    readonly id: number = 0
    readonly global_network_id: number = 0
    readonly title: string = ''
    readonly description: string = ''
    readonly uid: string = ''
    readonly master_company_id: number = 0
    readonly master_company: GlobalNetworkCompany = new Company()
}

export { GlobalNetwork }