import { Space, SpaceProps as AntSpaceProps } from 'antd'
import { FC, PropsWithChildren } from 'react'

type SpaceProps = AntSpaceProps & {
  justify?: '' | 'left' | 'center' | 'right' | 'space-between'
}

const VerticalSpace: FC<SpaceProps & PropsWithChildren> = ({ justify, style, children, ...baseProps }) => {
  return (
    <Space
      wrap={false}
      style={{ width: '100%', justifyContent: justify, ...style }}
      direction={'vertical'}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

export default VerticalSpace
