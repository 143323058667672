import { PropsWithChildren } from 'react'
import { ApiError } from '../api/error'
import { Result } from 'antd'
import React from 'react'

export class ErrorBoundary extends React.Component<PropsWithChildren> {
  state: {
    error?: any
    hasError: false
    componentError?: {
      message: string
      stack: string
    }
  }

  constructor(props) {
    super(props)
    this.state = { error: undefined, hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error, hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    return {
      hasError: true,
      componentError: {
        message: error.message,
        stack: errorInfo.componentStack,
      },
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error instanceof ApiError) {
        switch (this.state.error?.code) {
          case 403:
            return (
              <Result status={'403'} title={`${this.state.error.code}`} subTitle={this.state.error.errorDescription} />
            )
          case 404:
          case 406:
            return (
              <Result status={'403'} title={`${this.state.error.code}`} subTitle={this.state.error.errorDescription} />
            )
          default:
            return (
              <Result
                status={'error'}
                title={`${this.state.error.code}`}
                subTitle={this.state.error.errorDescription}
              />
            )
        }
      }
      return <Result status={'error'} title={this.state.error.message} subTitle={this.state.error.stack} />
    }

    return this.props.children
  }
}
