import { Fragment, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import CompanySelect from 'src/components/company/CompanySelect'
import { useAuth } from './AuthProvider'
import { withPrefix } from './ConfigProvider'
import { App, Modal } from 'antd'

type CompanySelectProps = {
  selectCompany: () => void
}

const CompanySelectContext = createContext<CompanySelectProps>({
  selectCompany: () => {},
})

const CompanySelectProvider = ({ children }: PropsWithChildren) => {
  const { authenticated, companyId } = useAuth()
  const [selectingCompany, setSelectingCompany] = useState(false)

  const selectCompany = () => setSelectingCompany(true)

  return (
    <CompanySelectContext.Provider
      value={{
        selectCompany,
      }}
    >
      {authenticated && !companyId ? (
        <div style={{ height: '100vh' }} className={withPrefix('login-view')}>
          <CompanySelect onSelect={() => setSelectingCompany(false)} />
        </div>
      ) : (
        <Fragment>
          {children}
          <Modal
            centered
            open={selectingCompany}
            onCancel={() => setSelectingCompany(false)}
            afterOpenChange={(open) => !open && setSelectingCompany(false)}
            destroyOnClose
            footer={null}
          >
            <CompanySelect onSelect={() => setSelectingCompany(false)} />
          </Modal>
        </Fragment>
      )}
    </CompanySelectContext.Provider>
  )
}

const useCompanySelect: () => CompanySelectProps = () => useContext(CompanySelectContext)
export { CompanySelectProvider, useCompanySelect }
