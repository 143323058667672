import { Button, Card, Col, Form, Input, notification, Row, Typography } from 'antd'
import { FC, useState } from 'react'
import { Navigate } from 'react-router'
import { RouteMap } from 'src/containers/RouteMap'
import { VerticalSpace } from 'src/sdk/components/space'
import { AuthCredentials } from 'src/sdk/datasource/auth/token'
import { useAuth } from 'src/sdk/providers/AuthProvider'
import { withPrefix } from 'src/sdk/providers/ConfigProvider'
import './Login.scss'

const Login: FC = () => {
  const [loading, setLoading] = useState(false)
  const { login, authenticated } = useAuth()

  const attemptLogin = (credentials: AuthCredentials) => {
    setLoading(true)
    login(credentials.username, credentials.password)
      .catch((err) => {
        notification.error({
          message: 'Invalid Username/Password Combo',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return authenticated ? (
    <Navigate to={RouteMap.home.path} />
  ) : (
    <div style={{ height: '100vh' }} className={withPrefix('login-view')}>
      <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
        <Col className={withPrefix('login-wrapper')}>
          <Card
            className={withPrefix('login-card')}
            cover={
              <VerticalSpace align={'center'} justify={'center'}>
                <Typography.Title level={4} className={withPrefix('login-card-title')}>
                  Reddick
                </Typography.Title>
                <Typography.Title level={1} className={withPrefix('login-card-title')}>
                  LET US BEGIN
                </Typography.Title>
              </VerticalSpace>
            }
          >
            <Form<AuthCredentials>
              autoComplete={'on'}
              autoSave={'on'}
              name={'login'}
              onFinish={attemptLogin}
              className={withPrefix('login-form')}
              initialValues={{
                rememberMe: true,
                grantType: 'password',
              }}
            >
              <VerticalSpace>
                <Form.Item
                  hasFeedback
                  validateTrigger='onBlur'
                  name={'username'}
                  rules={[
                    {
                      required: true,
                      validator: async (_, value: string) => {
                        if (!value) {
                          return Promise.reject('Please enter a username or email')
                        }
                        if (!value.endsWith('@peoplevine.com')) {
                          return Promise.reject('Please enter a valid Peoplevine email address')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    placeholder={'Email or Username'}
                    autoComplete={'username'}
                    type={'text'}
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item
                  name={'password'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    type={'Enter your password'}
                    placeholder={'Password'}
                    autoComplete={'password'}
                    disabled={loading}
                  />
                </Form.Item>
                <Button block size={'large'} type={'primary'} loading={loading} disabled={loading} htmlType={'submit'}>
                  Login
                </Button>
              </VerticalSpace>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Login
