import { initialState } from '@data-client/core'
import {
  CacheProvider,
  NetworkManager,
  PollingSubscription,
  SubscriptionManager,
  getDefaultManagers,
} from '@data-client/react'
import { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import { LayoutProvider } from 'src/layouts'
import { FullPageLoader } from 'src/sdk/components/loader/Loader'
import { AuthProvider } from 'src/sdk/providers/AuthProvider'
import ConfigProvider from 'src/sdk/providers/ConfigProvider'
import { NotificationProvider } from 'src/sdk/providers/NotificationProvider'
import { RouteProvider } from './RouteProvider'

const networkManager = new NetworkManager()
const subscriptionManager = new SubscriptionManager(PollingSubscription)

const Root = () => {
  return (
    <CacheProvider
      initialState={initialState}
      managers={[...getDefaultManagers(), networkManager, subscriptionManager]}
    >
      <ConfigProvider>
        <CookiesProvider>
          <AuthProvider>
            <Suspense fallback={<FullPageLoader loading={true} />}>
              <NotificationProvider>
                <LayoutProvider>
                  <RouteProvider />
                </LayoutProvider>
              </NotificationProvider>
            </Suspense>
          </AuthProvider>
        </CookiesProvider>
      </ConfigProvider>
    </CacheProvider>
  )
}
export default Root
