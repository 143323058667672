export function removeEmptyParams<T extends object = object>(obj: T): Partial<T> {
    return Object.entries(obj)
        .filter(([, value]) => Array.isArray(value) ? value.length > 0 : value !== undefined && value !== '' && value !== null)
        .reduce((acc, [key, value]) => {
            acc = acc ?? []
            if (Array.isArray(value))
                acc[key] = value.join(',')
            else if (isObject(value))
                acc = { ...acc, ...objectToQueryString(key, value) }
            else
                acc[key] = value

            return acc
        }, {})
}

function isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value)
}

function objectToQueryString(parentKey: string, value: any): object {
    return Object.entries(removeEmptyParams(value)).reduce((acc, [key, value]) => ({ ...acc, [`${parentKey}[${key}]`]: value }), {})
}