export type ApiErrorItem = {
    details?: string
    referenceId?: number
    referenceType: string
}

export class ApiError extends Error {
    code: number = 0
    error: string = ''
    title: string = ''
    errorMessage: string = ''
    errorDescription: any
    errorDetails?: ApiErrorItem[]
    uri: string = ''

    name = 'ApiError'

    constructor(code?: number, json: any = {}) {
        super(json?.error_description ?? '')
        this.uri = window.location.pathname

        if (code) this.code = code

        if (this.code === 404) {
            this.error = 'not_found'
            this.errorMessage = 'The resource you are looking for could not be found.'
        } else {
            this.error = json.error
            this.title = json.title
            this.errorMessage = json.error_message
            this.errorDescription = json.error_description
            this.errorDetails = json.error_details ? json.error_details : []
        }
    }
}
