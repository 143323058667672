import { Buffer } from 'buffer'

export default function useBase64() {
    const base64Encode = (data: any) => {
        let dataString = typeof (data) !== 'string' ? JSON.stringify(data) : data
        return Buffer.from(dataString).toString('base64')
    }

    const base64Decode = (data: string) => {
        return Buffer.from(data, 'base64').toString('ascii')
    }

    return {
        base64Encode,
        base64Decode
    }
}