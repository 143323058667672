import { Layout } from 'antd'
import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router'
import SideNav from 'src/components/navigation/SideNav'
import TopNav from 'src/components/navigation/TopNav'
import { SectionLoader } from 'src/sdk/components/loader/Loader'
import { AccountProvider } from 'src/sdk/providers/AccountProvider'
import { useAuth } from 'src/sdk/providers/AuthProvider'
import { withPrefix } from 'src/sdk/providers/ConfigProvider'
import './Authorized.scss'
import { ErrorBoundary } from 'src/sdk/providers/ErrorBoundary'
import { CompanySelectProvider } from 'src/sdk/providers/CompanySelectProvider'

const { Content } = Layout
const Authorized = () => {
  const { authType, authenticated } = useAuth()
  if (!authenticated) return <Navigate to={'/login'} />
  return (
    <CompanySelectProvider>
      <AccountProvider>
        {authType === 'user_company' && <SideNav />}
        <Layout className={withPrefix('site-container')}>
          {authType === 'user_company' && <TopNav />}
          <Content>
            <Suspense fallback={<SectionLoader />}>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </Suspense>
          </Content>
        </Layout>
      </AccountProvider>
    </CompanySelectProvider>
  )
}
export default Authorized
