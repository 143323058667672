import { ApiEndpoint, ApiEntity, CreateApiResource } from "../ApiResource"
import { UserRole } from "./userRole"

export type UserStatus = 'active' | 'inactive' | 'pending' | 'change' | ''

export type UserFilter = {
    status?: UserStatus
    search?: string
    order_by: 'first_name' | 'last_name' | 'status' | 'newest' | 'oldest'
} & ApiPaginationQuery


class User extends ApiEntity {
    readonly id: number = 0
    readonly last_accessed: string = ''
    readonly first_name: string = ''
    readonly last_name: string = ''
    readonly full_name: string = ''
    readonly mobile_number: string = ''
    readonly email: string = ''
    readonly username: string = ''
    readonly mobile_number: string = ''
    readonly status: UserStatus = ''
    readonly created_on: string = ''
    readonly modified_on: string = ''
    readonly profile_photo?: string
    readonly role: string = ''
}

class CompanyUser extends ApiEntity {
    readonly id: number = 0
    readonly user_id: number = 0
    readonly company_id: number = 0
    readonly role?: UserRole
    readonly status: string = 'active'
    readonly user: User = User.fromJS()

    static schema = {
        user: User
    }
}

const UserResourceBase = CreateApiResource({
    path: '/users/:id',
    schema: User
});

const ListUsers = new ApiEndpoint({
    path: '/users',
    schema: [User]
})

const getCurrentUser = new ApiEndpoint({
    path: '/users',
    schema: User
})

const getRoles = new ApiEndpoint({
    path: '/users/:id/role',
    schema: UserRole
})

const UserResource = {
    ...UserResourceBase,
    list: ListUsers,
    getCurrentUser,
    getRoles
};

export { CompanyUser, User, UserResource }

